import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { colors } from "../../../utils";
import {
  useGetADistributorByIdQuery,
  useUpdateDistributorByIdMutation,
} from "../../../app/services/adminApi";
import { toast } from "react-hot-toast";

const AdminEditDistributor: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get distributor ID from URL
  const navigate = useNavigate();

  // Fetch the distributor data by ID
  const { data, isLoading, isError } = useGetADistributorByIdQuery(id || "");

  const distributor = data?.distributor;

  // Initialize mutation for updating the distributor
  const [updateDistributor, { isLoading: isUpdating }] =
    useUpdateDistributorByIdMutation();

  // Form state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    tag: "",
    country: "",
    state: "",
    lga: "",
  });

  // Preload form data when distributor data is available
  useEffect(() => {
    if (distributor) {
      setFormData({
        firstName: distributor.firstName || "",
        lastName: distributor.lastName || "",
        email: distributor.email || "",
        phoneNo: distributor.phoneNo || "",
        tag: distributor.tag || "",
        country: distributor.country || "",
        state: distributor.state || "",
        lga: distributor.lga || "",
      });
    }
  }, [distributor]);

  // Handle form changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateDistributor({ id, data: formData }).unwrap();
      toast.success("Distributor updated successfully!");
      navigate(-1);
    } catch (error) {
      toast.error("Failed to update distributor. Please try again.");
    }
  };

  if (isLoading) return <Container>Loading distributor data...</Container>;
  if (isError) return <Container>Failed to load distributor data.</Container>;

  return (
    <Container>
      <FormWrapper>
        <h2>Update Distributor</h2>
        <Form onSubmit={handleSubmit}>
          <Label>
            First Name:
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </Label>
          <Label>
            Last Name:
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </Label>
          <Label>
            Email:
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Label>
          <Label>
            Phone Number:
            <Input
              type="text"
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleChange}
              required
            />
          </Label>
          <Label>
            Tag:
            <Select
              name="tag"
              value={formData.tag}
              onChange={handleChange}
              required
            >
              <option value="">Select Tag</option>
              <option value="rep">Representative</option>
              <option value="owner">Owner</option>
            </Select>
          </Label>
          <Label>
            Country:
            <Input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </Label>
          <Label>
            State:
            <Input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </Label>
          <Label>
            LGA:
            <Input
              type="text"
              name="lga"
              value={formData.lga}
              onChange={handleChange}
              required
            />
          </Label>
          <Button type="submit" disabled={isUpdating}>
            {isUpdating ? "Updating..." : "Update Distributor"}
          </Button>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default AdminEditDistributor;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightBack};
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  background: ${colors.white};
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: ${colors.black};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  color: ${colors.dark};
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  margin-top: 0.5rem;
  &:focus {
    outline: none;
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px ${colors.greyText};
  }
`;

const Select = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  margin-top: 0.5rem;
  &:focus {
    outline: none;
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px ${colors.greyText};
  }
`;

const Button = styled.button`
  padding: 0.5rem;
  font-size: 1rem;
  background-color: ${colors.green};
  color: ${colors.white};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.green};
  }

  &:disabled {
    background-color: ${colors.grey};
    cursor: not-allowed;
  }
`;
