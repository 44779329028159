import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectDistributor } from "../../../app/features/distributorSlice";
import { styled } from "styled-components";
import { useGetOrderByIdQuery } from "../../../app/services/orderApi";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";

const DistributorInvoiceDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { distributor } = useAppSelector(selectDistributor);
  const distributorId = distributor.id;

  const { data, isLoading, error } = useGetOrderByIdQuery({
    orderId,
    distributorId,
  });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading order details.</p>;
  }

  const handlePaymentOnline = () => {
    navigate(`/distributor-dashboard/payment/${orderId}`);
  };

  const handlePayWithBank = () => {
    navigate(`/distributor-dashboard/payment/${orderId}/pay-with-bank`);
  };

  return (
    <Container>
      <Wrapper>
        <Title>Invoice for Order #{data?.orderId}</Title>

        <DoubleSection>
          <Section>
            <SubTitle>Shipping Address</SubTitle>
            <p>
              <strong>Full Name:</strong> {data?.shippingAddress.fullName}
            </p>
            <p>
              {data?.shippingAddress.address}, {data?.shippingAddress.city}
            </p>
            <p>
              {data?.shippingAddress.postalCode},{" "}
              {data?.shippingAddress.country}
            </p>
          </Section>

          <Section>
            <SubTitle>Order Details</SubTitle>
            <p>
              <strong>Order ID:</strong> {data?.orderId}
            </p>
            <p>
              <strong>Paid:</strong> {data?.isPaid ? "Yes" : "No"}
            </p>
            <p>
              <strong>Delivered:</strong> {data?.isDelivered ? "Yes" : "No"}
            </p>
          </Section>
        </DoubleSection>

        <Section>
          <SubTitle>Order Items</SubTitle>
          <Table>
            <thead>
              <tr>
                <TableHeader>Product</TableHeader>
                <TableHeader>Quantity</TableHeader>
                <TableHeader>Unit Price</TableHeader>
                <TableHeader>Total</TableHeader>
                <TableHeader>Shipping Price</TableHeader>
                <TableHeader>Total with Shipping</TableHeader>
              </tr>
            </thead>
            <tbody>
              {data?.orderItems.map((item: any) => {
                const price = item.product
                  ? item.product.discountedPrice ?? item.product.price
                  : 0;
                const total = data.totalPrice;

                return (
                  <TableRow key={item._id}>
                    <TableData>
                      {item.product
                        ? item.product.name
                        : "Product not available"}
                    </TableData>

                    <TableData style={{ textAlign: "center" }}>
                      {item.quantity.toLocaleString()}
                    </TableData>
                    <TableData>₦{price.toLocaleString()}</TableData>
                    <TableData>₦{total.toLocaleString()}</TableData>
                    <TableData>
                      {data.shippingPrice ? (
                        `₦${data.shippingPrice.toLocaleString()}`
                      ) : (
                        <Proof>
                          <p>Contact Sales Rep</p>
                        </Proof>
                      )}
                    </TableData>
                    <TableData>
                      {data.totalWithShipping ? (
                        `₦${data.totalWithShipping.toLocaleString()}`
                      ) : (
                        <Proof>
                          <p>Contact Sales Rep</p>
                        </Proof>
                      )}
                    </TableData>
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </Section>
        <Section>
          <SubTitle>Pricing</SubTitle>
          <p>
            <strong>Items Price:</strong> ₦{data.itemsPrice.toLocaleString()}
          </p>
          <p>
            <strong>Total Price:</strong> ₦{data.totalPrice.toLocaleString()}
          </p>
          {data.totalWithShipping ? (
            <p>
              <strong>Total with Shipping:</strong> ₦
              {data.totalWithShipping.toLocaleString()}
            </p>
          ) : (
            <p>
              <strong>Total with Shipping:</strong>
            </p>
          )}
        </Section>

        <PaymentSection>
          {data?.proofOfPayment ? (
            <Proof>
              <h3>Proof of Payment:</h3>
              <img src={data.proofOfPayment} alt="Proof of Payment" />
            </Proof>
          ) : (
            <>
              {data.totalWithShipping && (
                <button onClick={handlePayWithBank}>
                  Pay with Bank ₦{data.totalWithShipping.toLocaleString()}
                </button>
              )}
              {/* {data.totalPrice <= 10000000 && data.authorizationUrl && (
                <button onClick={handlePaymentOnline}>
                  Pay Online ₦{data.totalWithShipping.toLocaleString()}
                </button>
              )} */}
            </>
          )}
        </PaymentSection>
      </Wrapper>
    </Container>
  );
};

export default DistributorInvoiceDetails;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  background-color: #f9f9f9;
`;

const Wrapper = styled.div`
  width: 80%;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
`;

const SubTitle = styled.h2`
  margin-top: 1rem;
  color: #4caf50;
  text-align: left;
`;

const DoubleSection = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const Section = styled.div``;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f4f4f4;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableData = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const PaymentSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #45a049;
    }
  }

  @media (max-width: ${screens.tab}) {
    flex-wrap: wrap;
    justify-content: center;

    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const Proof = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 1rem;
    font-weight: 500;
  }

  img {
    width: fit-content;
    object-fit: contain;
    height: 15rem;
    border-radius: 0.7rem;
  }
`;
