import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { colors, screens, typography } from "../../../utils";
import TabsWrapper from "../../../common/TabsWrapper";
import PendingPayments from "./PendingPayments";
import ShippingOn from "./ShippingOn";
import DeliveredOrder from "./DeliveredOrder";

const tabItems = ["Payment Pending", "Shipping On", "Delivered"];

const DistributorOrders = () => {
  const [tab, setTab] = useState<string>(() => {
    const storedTab = sessionStorage.getItem("activeTab");
    return storedTab || tabItems[0];
  });

  useEffect(() => {
    sessionStorage.setItem("activeTab", tab);
  }, [tab]);

  const renderChildren = () => {
    switch (tab) {
      case tabItems[0]:
        return <PendingPayments />;
      case tabItems[1]:
        return <ShippingOn />;
      case tabItems[2]:
        return <DeliveredOrder />;
      default:
        return <PendingPayments />;
    }
  };

  const renderNavItems = () => {
    return (
      <TabList className="sub-nav">
        {tabItems.map((item) => (
          <li
            key={item}
            className={item === tab ? "selected" : ""}
            onClick={() => setTab(item)}
          >
            {item}
          </li>
        ))}
      </TabList>
    );
  };

  // useEffect(() => {
  //   if (orders) {
  //     setOrdersData(orders);
  //   }
  // }, [orders]);

  // useEffect(() => {
  //   if (isError) {
  //     const customError = error as CustomError;
  //     const errorMessage =
  //       customError?.data?.error || "Oops! Something went wrong";
  //     toast.error(errorMessage);
  //     return;
  //   }
  // }, [isError, error]);

  return (
    <Container>
      <Wrapper>
        <TabsWrapper renderNavItems={renderNavItems}>
          <TabsContent>{renderChildren()}</TabsContent>
        </TabsWrapper>
      </Wrapper>
    </Container>
  );
};

export default DistributorOrders;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: 1.5rem auto;

  h3 {
    font: ${typography.Lead};
    margin-bottom: 1rem;
    font-weight: 600;
    color: ${colors.C1A};
  }

  .order {
    width: 25%;
  }

  .date {
    width: 10%;
  }

  .status {
    width: 25%;
  }

  .price {
    width: 15%;
  }

  .paid {
    width: 5%;
  }

  .delivered {
    width: 7%;
  }
`;

const TabList = styled.ul`
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
  gap: 2rem;
  flex-wrap: wrap;

  @media Screen and (max-width: ${screens.tab}) {
    gap: 1rem;
  }

  li {
    padding: 0.2rem 1rem;
    background-color: ${colors.white};
    border-radius: 0.25rem;
    cursor: pointer;
    color: ${colors.dark};
    font-size: 0.9rem;
    border: 1px solid ${colors.green};

    @media Screen and (max-width: ${screens.tab}) {
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .selected {
    background-color: ${colors.green};
    color: ${colors.white};
  }
`;

const TabsContent = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  overflow: scroll;
  padding-bottom: 2rem;
`;
