import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import TabsWrapper from "../../../common/TabsWrapper";
import AdminPaidForOrders from "./AdminPaidForOrders";
import AdminOrdersInShipping from "./AdminOrdersInShipping";
import AdminDeliveredOrders from "./AdminDeliveredOrders";

const tabItems = ["Payment Pending", "Shipping On", "Delivered"];

const AdminOrders = () => {
  const [tab, setTab] = useState<string>(() => {
    const storedTab = sessionStorage.getItem("activeTab");
    return storedTab || tabItems[0];
  });

  useEffect(() => {
    sessionStorage.setItem("activeTab", tab);
  }, [tab]);

  const renderChildren = () => {
    switch (tab) {
      case tabItems[0]:
        return <AdminPaidForOrders />;
      case tabItems[1]:
        return <AdminOrdersInShipping />;
      case tabItems[2]:
        return <AdminDeliveredOrders />;
      default:
        return <AdminPaidForOrders />;
    }
  };

  const renderNavItems = () => {
    return (
      <TabList className="sub-nav">
        {tabItems.map((item) => (
          <li
            key={item}
            className={item === tab ? "selected" : ""}
            onClick={() => setTab(item)}
          >
            {item}
          </li>
        ))}
      </TabList>
    );
  };

  return (
    <Container>
      <Wrapper>
        <TabsWrapper renderNavItems={renderNavItems}>
          <TabsContent>{renderChildren()}</TabsContent>
        </TabsWrapper>
      </Wrapper>
    </Container>
  );
};

export default AdminOrders;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  flex-direction: column;
  gap: 1rem;
`;

const TabList = styled.ul`
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
  gap: 2rem;
  flex-wrap: wrap;

  @media Screen and (max-width: ${screens.tab}) {
    gap: 1rem;
  }

  li {
    padding: 0.2rem 1rem;
    background-color: ${colors.white};
    border-radius: 0.25rem;
    cursor: pointer;
    color: ${colors.dark};
    font-size: 0.9rem;
    border: 1px solid ${colors.green};

    @media Screen and (max-width: ${screens.tab}) {
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .selected {
    background-color: ${colors.green};
    color: ${colors.white};
  }
`;

const TabsContent = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  overflow: scroll;
  padding-bottom: 2rem;
`;
