import React from "react";
import { styled } from "styled-components";
import { colors } from "../../../utils";
import { Link } from "react-router-dom";
import SmallCountdownTimer from "../DiscountManager/SmallCountdownTimer";

interface PriceProps {
  hasDiscount: boolean;
}

interface ProductItemProps {
  product: {
    _id: string;
    name: string;
    price: number;
    countInStock: number;
    images: string[];
    discountTime?: string;
    discountPrice?: number;
    discountCode?: number;
  };
  to: string;
}

const AdminProductsCard: React.FC<ProductItemProps> = ({ product, to }) => {
  return (
    <Container to={to}>
      <Wrapper>
        <ProductImageCard>
          <img src={product.images[0]} alt={product.name} />
        </ProductImageCard>

        <ProductInfo>
          <TitleContainer>
            <h4>{product.name}</h4>
          </TitleContainer>

          <Content>
            <DiscountWrapper>
              <PriceContainer>
                <Price hasDiscount={!!product.discountPrice}>
                  {product.price.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Price>
                {product.discountPrice && (
                  <DiscountPrice>
                    {product.discountPrice.toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </DiscountPrice>
                )}
              </PriceContainer>
              {product.discountCode && (
                <DiscountCode>{product.discountCode}% Off</DiscountCode>
              )}
            </DiscountWrapper>
            {product.discountTime && (
              <SmallCountdownTimer
                targetDate={new Date(product.discountTime).toISOString()}
              />
            )}
          </Content>
        </ProductInfo>
      </Wrapper>
    </Container>
  );
};

export default AdminProductsCard;

const Container = styled(Link)`
  display: flex;
  width: 20rem;
  border-radius: 0.25rem;
  height: fit-content;
  border: 1.5px solid ${colors.C1D};
  text-decoration: none;

  &:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 16px;
    transition: all 0.3s ease-in-out;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  gap: 1rem;
`;

const ProductImageCard = styled.div`
  display: flex;
  width: 100%;
  height: 15rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  text-decoration: none;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;

  h4 {
    font-weight: 500;
    color: ${colors.black};
    font-size: 1rem;
    text-transform: capitalize;
    width: 100%;
    line-height: 1.2rem; /* Sets consistent spacing between lines */
    height: calc(1.2rem * 2); /* Ensures the height matches exactly 2 lines */
    overflow: hidden; /* Prevents overflow */
    text-overflow: ellipsis; /* Adds "..." for overflow */
    display: -webkit-box; /* Enables webkit box model */
    -webkit-line-clamp: 2; /* Limits to 2 lines */
    -webkit-box-orient: vertical; /* Sets the box's orientation to vertical */
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 0.5rem;
`;

const DiscountWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;

const Price = styled.p<PriceProps>`
  font-size: 1rem;
  color: ${(props) => (props.hasDiscount ? colors.grey : colors.black)};
  text-decoration: ${(props) => (props.hasDiscount ? "line-through" : "none")};
`;

const DiscountPrice = styled.p`
  font-size: 0.8rem;
  color: ${colors.black};
  font-weight: 500;
`;

const DiscountCode = styled.p`
  font-size: 0.8rem;
  color: ${colors.white};
  font-weight: 600;
  padding: 0.1rem 0.2rem;
  background-color: ${colors.green};
  border-radius: 0.25rem;
  width: fit-content;
`;
