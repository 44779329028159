import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGenerateInvoiceMutation,
  useGetOrderByIdQuery,
} from "../../../app/services/orderApi";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import { colors } from "../../../utils";

// Component
const AdminDistributorGenerateInvoice = () => {
  const { distributorId, orderId } = useParams();
  const navigate = useNavigate();
  const {
    data,
    isLoading: dataIsLoading,
    isError: dataIsError,
  } = useGetOrderByIdQuery({ orderId, distributorId });

  // Initialize the mutation hook
  const [generateInvoice, { isLoading }] = useGenerateInvoiceMutation();

  const handleGenerateInvoice = async () => {
    if (distributorId && orderId) {
      try {
        await generateInvoice({ distributorId, orderId }).unwrap();
        navigate(
          `/admin-dashboard/orders/${distributorId}/${orderId}/invoice-details`
        );
        toast.success("Invoice generated successfully!");
      } catch (err) {
        toast.error("Error generating invoice");
      }
    } else {
      toast.error("Invalid distributor or order ID.");
    }
  };

  return (
    <Container>
      <Card>
        <Title>Generate Invoice</Title>
        <ItemWrapper>
          <h3>
            <strong>Order Id: </strong>
            {data?.orderId}
          </h3>
          <p>
            <strong>Order Price: </strong>₦{data?.totalPrice.toLocaleString()}
          </p>
          <p>
            <strong>Shipping Price: </strong>₦
            {data?.shippingPrice.toLocaleString()}
          </p>
          <p>
            <strong>Total with Shipping: </strong>₦
            {data?.totalWithShipping.toLocaleString()}
          </p>
        </ItemWrapper>
        <Description>
          Click the button below to generate an invoice for this order.
        </Description>
        <Button onClick={handleGenerateInvoice} disabled={isLoading}>
          {isLoading ? "Generating..." : "Generate Invoice"}
        </Button>
      </Card>
    </Container>
  );
};

export default AdminDistributorGenerateInvoice;

// Styled Components
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f7fa;
  padding: 1rem;
`;

const Card = styled.div`
  background-color: #fff;
  padding: 2rem;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  color: ${colors.C1B || "#333"};
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
`;

const Button = styled.button`
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: ${colors.C1B || "#007bff"};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.C2B || "#0056b3"};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
  padding: 0.5rem;
  background-color: ${colors.C1E};
  border-radius: 0.5rem;
`;
