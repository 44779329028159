import React, { useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectDistributor } from "../../../app/features/distributorSlice";
import { Link, useNavigate } from "react-router-dom";
import { useGetAllOrdersByUserQuery } from "../../../app/services/orderApi";
import Spinner from "../../../utils/Spinner";
import { colors, typography } from "../../../utils";
import { styled } from "styled-components";
import StatusesTag from "../../../utils/StatusesTag";
import Pagination from "../../../common/Pagination";

const PendingPayments = () => {
  const { distributor } = useAppSelector(selectDistributor);
  const navigate = useNavigate();
  const distributorId = distributor.id;

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Fetch all orders for the distributor
  const {
    data: orders,
    isLoading,
    isError,
  } = useGetAllOrdersByUserQuery(distributorId, {
    skip: !distributorId,
  });

  if (!distributorId) {
    navigate("/sign-in/distributor");
    return null;
  }

  // Time format function
  const formatOrderDate = (createdAt: string) => {
    const orderDate = new Date(createdAt);
    const options = {
      year: "numeric" as const,
      month: "short" as const,
      day: "numeric" as const,
    };
    return orderDate.toLocaleDateString(undefined, options);
  };

  // Filter orders to show only unpaid ones
  const unpaidOrders = orders?.filter((order: any) => !order.isPaid) || [];

  // Calculate pagination
  const totalItems = unpaidOrders.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedOrders = unpaidOrders.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.C2C} />
        ) : isError ? (
          <p>Oops!!! Something went wrong...</p>
        ) : unpaidOrders.length > 0 ? (
          <>
            <Heading>
              <h4 className="order">Order</h4>
              <h4 className="date">Date</h4>
              <h4 className="status">Status</h4>
              <h4 className="price">Price</h4>
            </Heading>

            <Body>
              {paginatedOrders.map((order: any) => (
                <Content key={order.id}>
                  <div className="order">
                    <Item
                      to={`/distributor-dashboard/order/details/${order.orderId}`}
                    >
                      {order.orderId}
                    </Item>
                  </div>
                  <div className="date">
                    <p>{formatOrderDate(order.createdAt)}</p>
                  </div>
                  <div className="status">
                    <StatusesTag tagColor={colors.C4B} text="Not paid" />
                  </div>
                  <div className="price">
                    <p>
                      {order.totalPrice.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </Content>
              ))}
            </Body>

            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <h3>No unpaid orders</h3>
        )}
      </Wrapper>
    </Container>
  );
};

export default PendingPayments;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 1.5rem auto;

  h3 {
    font: ${typography.Lead};
    margin-bottom: 1rem;
    font-weight: 600;
    color: ${colors.C1A};
  }

  .order {
    width: 25%;
  }

  .date {
    width: 10%;
  }

  .status {
    width: 25%;
  }

  .price {
    width: 15%;
  }
`;

const Heading = styled.div`
  display: flex;
  width: 100%;
  border-top: 0.5px solid ${colors.C1D};
  border-bottom: 0.5px solid ${colors.C1D};
  margin-bottom: 1rem;
  gap: 1rem;

  h4 {
    font: ${typography.Caption};
    margin: 0.5rem 0;
    font-weight: 600;
    color: ${colors.C1B};
  }
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  border-top: 0.5px solid ${colors.C1D};
  border-bottom: 0.5px solid ${colors.C1D};
  gap: 1rem;

  div {
    margin: 0.5rem 0;
  }

  p {
    font: ${typography.Caption};
    text-transform: capitalize;
    max-width: inherit;
    color: ${colors.C1B};
    margin: 0;
  }
`;

const Item = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${colors.C1B};
  font: ${typography.Caption};
  text-transform: capitalize;
`;
