import React, { useState } from "react";
import {
  useDeleteOrderByIdMutation,
  useGetAllOrdersQuery,
} from "../../../app/services/adminApi";
import { CustomError } from "../../../../types";
import { colors, typography } from "../../../utils";
import { styled } from "styled-components";
import Pagination from "../../../common/Pagination";
import AdminOrderCard from "./AdminOrderCard";
import Spinner from "../../../utils/Spinner";
import { deleteImageFromFirebase } from "../../../lib/firebase-client";

const AdminDeliveredOrders = () => {
  const { data, isLoading, isError, refetch } = useGetAllOrdersQuery({});
  const [deleteOrderById] = useDeleteOrderByIdMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of orders to display per page

  // Filter orders where isDelivered is true
  const deliveredOrders =
    data?.filter((order: any) => order.isDelivered === true) || [];

  // Sort delivered orders by createdAt in descending order (most recent first)
  const sortedData = [...deliveredOrders].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  // Calculate total items and pages
  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Paginate sorted data
  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Transform data into the shape expected by AdminOrderCard
  const formatOrders = (orders: any[]) =>
    orders.map((order) => ({
      distributor: {
        firstName: order.distributor.firstName,
        lastName: order.distributor.lastName,
        id: order.distributor._id,
      },
      orders: [
        {
          orderId: order.orderId,
          totalPrice: order.totalPrice,
          id: order._id,
          isPaid: order.isPaid,
          createdAt: order.createdAt,
          isDelivered: order.isDelivered,
          isConfirmed: order.isConfirmed,
        },
      ],
    }));

  const handleDelete = async (orderId: any) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this order?"
    );
    if (confirmDelete) {
      try {
        const response = await deleteOrderById(orderId).unwrap();

        if (response.proofOfPayment) {
          try {
            await deleteImageFromFirebase(response.proofOfPayment);
          } catch (error) {
            const customError = error as CustomError;
            alert(
              `Failed to delete proof of payment image. Error: ${
                customError.data?.error || "Unknown error"
              }`
            );
            return;
          }
        }

        alert("Order deleted successfully.");
        refetch();
      } catch (error) {
        const customError = error as CustomError;
        alert(
          `Failed to delete order. Error: ${
            customError.data?.error || "Unknown error"
          }`
        );
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Oops! Something went wrong</p>
        ) : sortedData.length > 0 ? (
          <>
            <Heading>
              <h4>Distributor</h4>
              <h4>Order</h4>
              <h4>Date</h4>
              <h4>Price</h4>
              <h4>Status</h4>
            </Heading>
            {formatOrders(paginatedData).map((formattedOrder, index) => (
              <AdminOrderCard
                key={index}
                distributor={formattedOrder.distributor}
                orders={formattedOrder.orders}
                onDelete={handleDelete}
              />
            ))}
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <p>No delivered orders found</p>
        )}
      </Wrapper>
    </Container>
  );
};

export default AdminDeliveredOrders;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 2rem auto;
  flex-direction: column;
  gap: 1rem;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${colors.C1D};
  padding: 0.5rem 2.8rem 0.5rem 0.5rem;
  gap: 1rem;

  h4 {
    font: ${typography.Caption};
    font-weight: 600;
    color: ${colors.C1B};
    margin: 0;
    flex: 1;
    text-align: left;
  }
`;
