import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteOrderByIdMutation, useGetADistributorByIdQuery } from "../../../app/services/adminApi";
import { styled } from "styled-components";
import { colors } from "../../../utils";
import Button from "../../../common/Button";
import AdminOrderCard from "../AdminOrders/AdminOrderCard";
import Pagination from "../../../common/Pagination";

const AdminSingleDistributor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetADistributorByIdQuery(id);
  const [deleteOrderById] = useDeleteOrderByIdMutation();
  const distributorData = data?.distributor;
  const ordersData = data?.orders;

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  if (isError) {
    return <Container>Error loading distributor details.</Container>;
  }

  const handleClick = () => {
    navigate("place-order");
  };

  const formattedOrders = ordersData?.map((order: any) => ({
    distributor: {
      firstName: distributorData?.firstName,
      lastName: distributorData?.lastName,
      id: distributorData?._id,
    },
    orders: [
      {
        orderId: order.orderId,
        totalPrice: order.totalPrice,
        id: order._id,
        isPaid: order.isPaid,
        createdAt: order.createdAt,
        isDelivered: order.isDelivered,
        isConfirmed: order.isConfirmed,
        authorizationUrl: order.authorizationUrl,
      },
    ],
  }));

  // Calculate total pages for pagination
  const totalItems = formattedOrders?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Paginate orders
  const paginatedOrders = formattedOrders?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleEditDistributor = () => {
    navigate("update");
  };

  const handleDelete = async (orderId: string) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this order?");
    if (confirmDelete) {
      try {
        await deleteOrderById(orderId).unwrap();
        alert("Order deleted successfully.");
        //refetch();
      } catch (error) {
        console.error("Failed to delete order:", error);
        alert("Failed to delete order.");
      }
    }
  };

  return (
    <Container>
      <Wrapper>
        <DistributorDetails>
          <TitleWrapper>
            <h2>Distributor Details</h2>
            <ButtonWrapper>
              <Button
                text="Place Order for Distributor"
                border="none"
                onClick={handleClick}
              />
            </ButtonWrapper>
          </TitleWrapper>

          <p>
            <strong>Full Name:</strong> {distributorData?.firstName}{" "}
            {distributorData?.lastName}
          </p>
          <p>
            <strong>Email:</strong> {distributorData?.email}
          </p>
          <p>
            <strong>Phone Number:</strong> {distributorData?.phoneNo}
          </p>
          <p>
            <strong>State:</strong> {distributorData?.state}
          </p>
          <p>
            <strong>LGA:</strong> {distributorData?.lga}
          </p>
          <p>
            <strong>Member Since:</strong>{" "}
            {new Date(distributorData?.createdAt).toLocaleString()}
          </p>

          <ButtonWrapper>
            <Button
              text="Edit Distributor"
              border="none"
              onClick={handleEditDistributor}
            />
          </ButtonWrapper>
        </DistributorDetails>

        <OrdersDetails>
          <h2>Orders</h2>
          {paginatedOrders && paginatedOrders.length > 0 ? (
            paginatedOrders.map((formattedOrder: any, index: any) => (
              <AdminOrderCard
                key={index}
                distributor={formattedOrder.distributor}
                orders={formattedOrder.orders}
                onDelete={handleDelete}
              />
            ))
          ) : (
            <p>No orders found for this distributor.</p>
          )}

          {/* Pagination Component */}
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
          />
        </OrdersDetails>
      </Wrapper>
    </Container>
  );
};

export default AdminSingleDistributor;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 2rem;
  margin: 2rem auto;
`;

const DistributorDetails = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${colors.lightBack};

  h2 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: ${colors.dark};
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: ${colors.dark};

    strong {
      font-weight: 600;
      color: ${colors.dark};
    }
  }
`;

const OrdersDetails = styled.div`
  padding: 1rem;

  h2 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: ${colors.dark};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;
