import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAddShippingPriceMutation } from "../../../app/services/orderApi";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: 50px auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h2`
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 16px;
  color: #555;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
  font-size: 14px;
`;

const AdminDistributorShippingPrice = () => {
  const { distributorId, orderId } = useParams(); // Extract distributorId and orderId
  const navigate = useNavigate();

  // State for shipping price input
  const [shippingPrice, setShippingPrice] = useState<number>(0);

  // RTK Query mutation hook to add shipping price
  const [addShippingPrice, { isLoading, isError, error }] =
    useAddShippingPriceMutation();

  // Function to handle shipping price submission
  const handleAddShippingPrice = async () => {
    try {
      await addShippingPrice({
        distributorId: distributorId!,
        orderId: orderId!,
        shippingPrice,
      }).unwrap();

      // Navigate to the payment page on success
      alert("Shipping price updated successfully!");
      navigate(`/admin-dashboard/payments/${distributorId}/${orderId}`);
    } catch (err) {
      console.error("Failed to update shipping price:", err);
    }
  };

  return (
    <Container>
      <Heading>Set Shipping Price</Heading>
      <FormGroup>
        <Label htmlFor="shippingPrice">Shipping Price:</Label>
        <Input
          id="shippingPrice"
          type="number"
          placeholder="Enter shipping price"
          value={shippingPrice}
          onChange={(e) => setShippingPrice(parseFloat(e.target.value))}
        />
      </FormGroup>
      <Button
        onClick={handleAddShippingPrice}
        disabled={isLoading || shippingPrice <= 0}
      >
        {isLoading ? "Updating..." : "Submit Shipping Price"}
      </Button>
      {isError && <ErrorMessage>{(error as any)?.data?.error || "Something went wrong!"}</ErrorMessage>}
    </Container>
  );
};

export default AdminDistributorShippingPrice;
