import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectDistributor } from "../../../app/features/distributorSlice";
import { useAddProofOfPaymentMutation } from "../../../app/services/orderApi";
import { toast } from "react-hot-toast";
import { uploadImage } from "../../../lib/firebase-client";
import { styled } from "styled-components";
import Spinner from "../../../utils/Spinner";
import { colors, screens } from "../../../utils";

const DistributorPayWithBank = () => {
  const { orderId } = useParams();
  const { distributor } = useAppSelector(selectDistributor);
  const [addProofOfPayment, { isLoading }] = useAddProofOfPaymentMutation();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validTypes = ["image/", "application/pdf"];
      if (!validTypes.some((type) => file.type.startsWith(type))) {
        toast.error("Only image or PDF files are allowed");
        return;
      }
      setSelectedFile(file);

      // Generate a preview for images
      if (file.type.startsWith("image/")) {
        setPreviewUrl(URL.createObjectURL(file));
      } else {
        setPreviewUrl(null);
      }
    }
  };

  const handleAddProof = async () => {
    if (!selectedFile) {
      toast.error("Please select an image or PDF to upload");
      return;
    }

    try {
      setIsUploading(true);
      const folderPath = `proof-of-payments/${distributor.id}`;
      const uploadedUrl = await uploadImage(selectedFile, folderPath);

      await addProofOfPayment({
        orderId: orderId,
        proofOfPayment: uploadedUrl,
      }).unwrap();

      toast.success("Proof of payment added successfully");
      setPreviewUrl(null);
      setSelectedFile(null);
    } catch (error) {
      toast.error("Error uploading proof of payment");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Container>
      <Wrapper>
        <PaymentsWrapper>
          <h2>
            Pay into one of the accounts below and come back to this page to
            upload your proof of payment
          </h2>
        </PaymentsWrapper>
        <BankWrapper>
          <Bank>
            <h3>1305148978</h3>
            <h4>Ozgun Enterprises</h4>
            <p>Providus Bank</p>
          </Bank>

          {/* <Bank>
            <h3>0572000016</h3>
            <h4>Vastpath Concept</h4>
            <p>GTBank Plc</p>
          </Bank>
          <Bank>
            <h3>1017190876</h3>
            <h4>Vastpath Concept</h4>
            <p>Zenith Bank Plc</p>
          </Bank> */}
        </BankWrapper>
        <h1>Upload Proof of Payment for order #{orderId}</h1>

        <input
          type="file"
          accept="image/*,application/pdf"
          onChange={handleFileChange}
        />

        {previewUrl ? (
          <div style={{ marginTop: "1rem" }}>
            <h2>Preview:</h2>
            <img
              src={previewUrl}
              alt="Proof of Payment Preview"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        ) : selectedFile?.type === "application/pdf" ? (
          <div style={{ marginTop: "1rem" }}>
            <h2>PDF Selected:</h2>
            <p>{selectedFile.name}</p>
          </div>
        ) : null}

        <ButtonWrapper>
          <button
            onClick={handleAddProof}
            disabled={isLoading || isUploading}
            style={{
              marginTop: "1rem",
              padding: "10px 20px",
              backgroundColor: isUploading ? "grey" : "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: isUploading ? "not-allowed" : "pointer",
            }}
          >
            {isUploading || isLoading ? (
              <Spinner color={colors.C1I} />
            ) : (
              "Submit Proof of Payment"
            )}
          </button>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default DistributorPayWithBank;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  border-radius: 0.6rem;
  width: 50%;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem auto;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${colors.C1H};

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }

  input {
    width: 50%;
  }

  img {
    height: 10rem;
    width: 10rem;
    object-fit: contain;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const PaymentsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
`;

const BankWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
`;

const Bank = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.C2D};
  padding: 1rem;
  border-radius: 0.5rem;

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
  }
`;
