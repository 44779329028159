import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOneOrderQuery } from "../../../app/services/adminApi";
import { styled } from "styled-components";
import { useConfirmPaymentMutation } from "../../../app/services/orderApi";
import Spinner from "../../../utils/Spinner";
import { colors } from "../../../utils";
import { toast } from "react-hot-toast";
import { CustomError } from "../../../../types";

const AdminOrderDetails: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const navigate = useNavigate();
  const { data, isLoading, isError, refetch } = useGetOneOrderQuery({
    orderId,
  });
  const [
    confirmPayment,
    {
      isLoading: confirmIsLoading,
      isSuccess: confirmIsSuccess,
      isError: confirmIsError,
    },
  ] = useConfirmPaymentMutation();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error loading order details.</p>;
  }

  if (!data) {
    return <p>No data found for this order.</p>;
  }

  const handlePaymentOnline = () => {
    if (data.authorizationUrl) {
      window.location.href = data.authorizationUrl;
    } else {
      toast.error("Authorization URL is not available.");
    }
  };

  const handlePayWithBank = () => {
    navigate(`/admin-dashboard/payments/${orderId}/pay-with-bank`);
  };

  const handleConfirmPayment = async () => {
    try {
      await confirmPayment(orderId).unwrap();
      refetch();
      toast.success("Payment confirmed");
    } catch (error) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      //toast.error(errorMessage);
      alert(errorMessage);
      return;
    }
  };

  const handleAddShipping = () => {
    navigate(
      `/admin-dashboard/distributors/${data.distributor._id}/${orderId}/shipping-price`
    );
  };

  const handleGenerateCode = () => {
    navigate(`/admin-dashboard/payments/${data.distributor._id}/${orderId}`);
  };

  return (
    <Container>
      <Wrapper>
        <Title>Order Details for Order #{data.orderId}</Title>

        <DoubleSection>
          <Section>
            <SubTitle>Shipping Address</SubTitle>
            <p>
              <strong>Full Name:</strong> {data.shippingAddress.fullName}
            </p>
            <p>
              {data.shippingAddress.address}, {data.shippingAddress.city}
            </p>
            <p>
              {data.shippingAddress.postalCode}, {data.shippingAddress.country}
            </p>
          </Section>

          <Section>
            <SubTitle>Order Information</SubTitle>
            <p>
              <strong>Order ID:</strong> {data.orderId}
            </p>
            <p>
              <strong>Created At:</strong>{" "}
              {new Date(data.createdAt).toLocaleString()}
            </p>
            <p>
              <strong>Paid:</strong> {data.isPaid ? "Yes" : "No"}
            </p>
            <p>
              <strong>Delivered:</strong> {data.isDelivered ? "Yes" : "No"}
            </p>
          </Section>
        </DoubleSection>

        <Section>
          <SubTitle>Order Items</SubTitle>
          <Table>
            <thead>
              <tr>
                <TableHeader>Product</TableHeader>
                <TableHeader>Quantity</TableHeader>
                <TableHeader>Unit Price</TableHeader>
                <TableHeader>Total</TableHeader>
                <TableHeader>Shipping Price</TableHeader>
                <TableHeader>Total with Shipping</TableHeader>
              </tr>
            </thead>
            <tbody>
              {data.orderItems.map((item: any) => {
                const price = item.product
                  ? item.product.discountedPrice ?? item.product.price
                  : 0;
                const total = data.totalPrice;

                return (
                  <TableRow key={item._id}>
                    <TableData>
                      {item.product
                        ? item.product.name
                        : "Product not available"}
                    </TableData>
                    <TableData style={{ textAlign: "center" }}>
                      {item.quantity.toLocaleString()}
                    </TableData>
                    <TableData>₦{price.toLocaleString()}</TableData>
                    <TableData>₦{total.toLocaleString()}</TableData>
                    <TableData>
                      {data.shippingPrice ? (
                        `₦${data.shippingPrice.toLocaleString()}`
                      ) : (
                        <Proof>
                          <button onClick={handleAddShipping}>
                            Add Shipping
                          </button>
                        </Proof>
                      )}
                    </TableData>
                    <TableData>
                      {data.totalWithShipping ? (
                        `₦${data.totalWithShipping.toLocaleString()}`
                      ) : (
                        <Proof>
                          <button onClick={handleAddShipping}>
                            Add Shipping
                          </button>
                        </Proof>
                      )}
                    </TableData>
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </Section>

        <Section>
          <SubTitle>Pricing</SubTitle>
          <p>
            <strong>Items Price:</strong> ₦{data.itemsPrice.toLocaleString()}
          </p>
          <p>
            <strong>Total Price:</strong> ₦{data.totalPrice.toLocaleString()}
          </p>
          {data.totalWithShipping ? (
            <p>
              <strong>Total with Shipping:</strong> ₦
              {data.totalWithShipping.toLocaleString()}
            </p>
          ) : (
            <p>
              <strong>Total with Shipping:</strong>
            </p>
          )}
        </Section>

        <PaymentSection>
          {data.proofOfPayment ? (
            <Proof>
              <h3>Proof of Payment:</h3>
              <img src={data.proofOfPayment} alt="Proof of Payment" />
              {!data.isPaid ? (
                <button onClick={handleConfirmPayment}>Verify Payment</button>
              ) : (
                <p>Payment has been verified</p>
              )}
            </Proof>
          ) : (
            <>
              {data.totalWithShipping && (
                <button onClick={handlePayWithBank}>
                  Pay with Bank ₦{data.totalWithShipping.toLocaleString()}
                </button>
              )}
              {data.totalPrice <= 10000000 && data.authorizationUrl && (
                <button onClick={handlePaymentOnline}>
                  {confirmIsLoading ? (
                    <Spinner color={colors.white} />
                  ) : (
                    `Pay Online ₦${data.totalWithShipping.toLocaleString()}`
                  )}
                </button>
              )}
              {data.shippingPrice && !data.authorizationUrl && (
                <button onClick={handleGenerateCode}>
                  {confirmIsLoading ? (
                    <Spinner color={colors.white} />
                  ) : (
                    `Generate Authorization Code`
                  )}
                </button>
              )}
            </>
          )}
        </PaymentSection>
      </Wrapper>
    </Container>
  );
};

export default AdminOrderDetails;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  background-color: #f9f9f9;
`;

const Wrapper = styled.div`
  width: 90%;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
`;

const SubTitle = styled.h2`
  margin-top: 1rem;
  color: #4caf50;
  text-align: left;
`;

const DoubleSection = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Section = styled.div``;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f4f4f4;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableData = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const PaymentSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #45a049;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;

    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const Proof = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 1rem;
    font-weight: 500;
  }

  img {
    width: fit-content;
    object-fit: contain;
    height: 15rem;
    border-radius: 0.7rem;
  }

  button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 0.4rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #45a049;
    }
  }
`;
