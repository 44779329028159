import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { Link } from "react-router-dom";

const TopFinisher = () => {
  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <h3>No. 1 Building Materials Store in Nigeria.</h3>
          <p>
            Shop at the No. 1 Building Materials store in Nigeria. Buy at
            disciunted prices, where quality meets with low cost. Also enjoy
            huge prizes when you shop.
          </p>
          <Button to="store">Go to Store</Button>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default TopFinisher;

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.green};
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 50%;
  margin-left: auto;
  flex-direction: column;
  gap: 0.8rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }

  h3 {
    font-size: 1.4rem;
    color: ${colors.white};
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    color: ${colors.offWhite};
  }
`;

const Button = styled(Link)`
  padding: 0.5rem 1rem;
  background-color: ${colors.yellow};
  text-decoration: none;
  color: ${colors.white};
  font-size: 0.9rem;
  display: flex;
  width: fit-content;
  border-radius: 0.5rem;
`;
