import React, { useEffect } from "react";
import { useAppDispatch } from "./app/hooks";
import { setAdmin } from "./app/features/adminSlice";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import HomePage from "./pages/HomePages";
import Admin from "./components/AdminAuth";
import AdminPages from "./pages/AdminPages";
import AdminScreens from "./components/AdminScreens";
import SingleAdmin from "./components/AdminScreens/SingleAdmin";
import UpdateAdmin from "./components/AdminScreens/UpdateAdmin";
import AllAdmins from "./components/AdminScreens/AllAdmins";
import AdminProducts from "./components/AdminScreens/AdminProducts";
import AddProduct from "./components/AdminScreens/AdminProducts/AddProduct";
import { setDistributor } from "./app/features/distributorSlice";
import Distributor from "./components/DistributorAuth";
import DistributorPages from "./pages/DistributorPages";
import DistributorScreens from "./components/DistributorScreens";
import DistributorOrders from "./components/DistributorScreens/DistributorOrders";
import DistributorStaff from "./components/DistributorScreens/DistributorStaff";
import DistributorPayments from "./components/DistributorScreens/DistributorPayments";
import DistributorMarketplace from "./components/DistributorScreens/DistributorMarketplace";
import SignInPage from "./pages/SignInPage";
import AdminManager from "./components/AdminScreens/AdminManager";
import AdminDistributors from "./components/AdminScreens/AdminDistributors";
import DiscountManager from "./components/AdminScreens/DiscountManager";
import HomePages from "./pages/HomePages";
import HomeScreens from "./components/HomeScreens";
import HomeStore from "./components/HomeScreens/HomeStore";
import ProductDetails from "./components/Products/ProductDetails";
import EditProduct from "./components/AdminScreens/AdminProducts/EditProduct";
import SearchResults from "./components/SearchResults";
import DistributorSearchResults from "./components/SearchResults/DistributorSearchResults";
import AdminSearchResults from "./components/SearchResults/AdminSearchResults";
import AdminOrders from "./components/AdminScreens/AdminOrders";
import DistributorCart from "./components/DistributorScreens/DistributorOrders/DistributorCart";
import DistributorShipping from "./components/DistributorScreens/DistributorOrders/DistributorShipping";
import DistributorOrderDetails from "./components/DistributorScreens/DistributorOrders/DistributorOrderDetails";
import DistributorOrderInfo from "./components/DistributorScreens/DistributorOrders/DistributorOrderInfo";
import AdminSingleDistributor from "./components/AdminScreens/AdminDistributors/AdminSingleDistributor";
import AdminPlaceOrderForDistributor from "./components/AdminScreens/AdminOrders/AdminPlaceOrderForDistributor";
import AdminOrderProductDetails from "./components/AdminScreens/AdminOrders/AdminOrderProductDetails";
import AdminDistributorCart from "./components/AdminScreens/AdminOrders/AdminDistributorCart";
import AdminDistributorShipping from "./components/AdminScreens/AdminOrders/AdminDistributorShipping";
import AdminDistributorOrderSummary from "./components/AdminScreens/AdminOrders/AdminDistributorOrderSummary";
import AdminDistributorGenerateInvoice from "./components/AdminScreens/AdminOrders/AdminDistributorGenerateInvoice";
import AdminDistributorInvoiceDetails from "./components/AdminScreens/AdminOrders/AdminDistributorInvoiceDetails";
import AdminOrderDetails from "./components/AdminScreens/AdminOrders/AdminOrderDetails";
import DistributorArtisans from "./components/DistributorScreens/DistributorArtisans";
import AddArtisan from "./components/DistributorScreens/DistributorArtisans/AddArtisan";
import AdminRaffle from "./components/AdminScreens/AdminRaffle";
import DistributorInvoiceDetails from "./components/DistributorScreens/DistributorPayments/DistributorInvoiceDetails";
import DistributorPayWithBank from "./components/DistributorScreens/DistributorPayments/DistributorPayWithBank";
import AdminPayments from "./components/AdminScreens/AdminPayments";
import DistributorTransactions from "./components/DistributorScreens/DistributorTransactions";
import SingleDistributorStaff from "./components/DistributorScreens/DistributorStaff/SingleDistributorStaff";
import AdminPayWithBank from "./components/AdminScreens/AdminPayments/AdminPayWithBank";
import AdminEditDistributor from "./components/AdminScreens/AdminDistributors/AdminEditDistributor";
import AdminDistributorShippingPrice from "./components/AdminScreens/AdminOrders/AdminDistributorShippingPrice";

function App() {
  const dispatch = useAppDispatch();

  const admin = JSON.parse(localStorage.getItem("admin") || "{}");
  const distributor = JSON.parse(localStorage.getItem("distributor") || "{}");

  useEffect(() => {
    dispatch(setAdmin(admin));
  }, [dispatch, admin]);

  useEffect(() => {
    dispatch(setDistributor(distributor));
  }, [dispatch, distributor]);

  return (
    <BrowserRouter>
      <Toaster position="top-right" />
      <Routes>
        <Route path="/" element={<HomePages />}>
          <Route path="/" element={<HomeScreens />} />
          <Route path="search-results" element={<SearchResults />} />
          <Route path="store" element={<HomeStore />} />
          <Route path="store/:id" element={<ProductDetails />} />
        </Route>

        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-in/admin" element={<Admin />} />
        <Route path="/admin-password-code" element={<Admin />} />
        <Route path="/admin-password-reset" element={<Admin />} />

        <Route path="/sign-up/distributor" element={<Distributor />} />
        <Route path="/sign-in/distributor" element={<Distributor />} />
        <Route path="/distributor-password-code" element={<Distributor />} />
        <Route path="/distributor-password-reset" element={<Distributor />} />

        <Route path="/admin-dashboard" element={<AdminPages />}>
          <Route path="home" element={<AdminScreens />} />
          <Route path="admins" element={<AdminManager />} />
          <Route path="payments" element={<AdminPayments />} />
          <Route path="raffles" element={<AdminRaffle />} />
          <Route path="discount-manager" element={<DiscountManager />} />
          <Route path="distributors" element={<AdminDistributors />} />
          <Route path="distributors/:id" element={<AdminSingleDistributor />} />
          <Route path="distributors/:id/update" element={<AdminEditDistributor />} />
          <Route
            path="distributors/:distributorId/place-order"
            element={<AdminPlaceOrderForDistributor />}
          />
          <Route
            path="distributors/:distributorId/place-order/:productId"
            element={<AdminOrderProductDetails />}
          />
          <Route path="products" element={<AdminProducts />} />
          <Route path="orders" element={<AdminOrders />} />
          <Route path="orders/:orderId" element={<AdminOrderDetails />} />
          <Route path="search-results" element={<AdminSearchResults />} />
          <Route
            path="products/:id"
            element={<ProductDetails isAdmin={true} />}
          />
          <Route path="products/:id/edit" element={<EditProduct />} />
          <Route path="products/create-product" element={<AddProduct />} />
          <Route path="admins/:id" element={<SingleAdmin />} />
          <Route path="admins/:id/update" element={<UpdateAdmin />} />

          <Route path="cart" element={<AdminDistributorCart />} />
          <Route
            path="distributors/:distributorId/shipping"
            element={<AdminDistributorShipping />}
          />
          <Route
            path="distributors/:distributorId/order-details"
            element={<AdminDistributorOrderSummary />}
          />
          <Route
            path="distributors/:distributorId/:orderId/shipping-price"
            element={<AdminDistributorShippingPrice />}
          />
          <Route
            path="payments/:distributorId/:orderId"
            element={<AdminDistributorGenerateInvoice />}
          />
          <Route
            path="orders/:distributorId/:orderId/invoice-details"
            element={<AdminDistributorInvoiceDetails />}
          />
          <Route path="payment/:orderId" element={<DistributorPayments />} />
          <Route
            path="payments/:orderId/pay-with-bank"
            element={<AdminPayWithBank />}
          />
        </Route>

        <Route path="/distributor-dashboard" element={<DistributorPages />}>
          <Route path="home" element={<DistributorScreens />} />
          <Route path="orders" element={<DistributorOrders />} />
          <Route path="payments" element={<DistributorTransactions />} />
          <Route
            path="order/details/:orderId"
            element={<DistributorInvoiceDetails />}
          />
          <Route path="cart" element={<DistributorCart />} />
          <Route path="shipping" element={<DistributorShipping />} />
          <Route path="order-details" element={<DistributorOrderDetails />} />
          <Route path="staff" element={<DistributorStaff />} />
          <Route path="staff/:id" element={<SingleDistributorStaff />} />
          <Route path="payment/:orderId" element={<DistributorPayments />} />
          <Route
            path="payment/:orderId/pay-with-bank"
            element={<DistributorPayWithBank />}
          />
          <Route
            path="payment/:orderId/invoice-details"
            element={<DistributorInvoiceDetails />}
          />
          <Route path="search-results" element={<DistributorSearchResults />} />
          <Route path="marketplace" element={<DistributorMarketplace />} />
          <Route path="marketplace/products/:id" element={<ProductDetails />} />
          <Route path="artisans" element={<DistributorArtisans />} />
          <Route path="artisans/add-artisan" element={<AddArtisan />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
